<template>
  <div>
    <v-dialog
      v-model="show_dialog"
      width="700"
      persistent
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title
          class="text-h6 white--text primary_2 d-flex justify-space-between"
        >
          Add KYC
          <v-btn @click="closeDialog()" icon
            ><v-icon color="white">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="pb-1">
          <v-container>
            <v-row>
              <v-col cols="6">
                <div class="font-weight-bold pb-2 text-body-1">
                  Photo
                </div>
                <div class="upload-main" elevation="0">
                  <v-icon v-if="!photo_preview" class="upload-icon"
                    >mdi-cloud-upload-outline</v-icon
                  >
                  <label for="photo_file" class=" upload-box">
                    <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                    <div v-if="photo_preview">
                      <v-img
                        width="178px"
                        height="147px"
                        class="rounded-lg"
                        alt="photo"
                        v-if="checkForImage(photo_file, photo_preview)"
                        :src="photo_preview"
                      >
                      </v-img>
                      <v-img
                        width="178px"
                        height="147px"
                        alt="photo"
                        class="rounded-lg"
                        v-else
                        src="@/assets/images/file_logo.png"
                      ></v-img>
                    </div>
                  </label>
                  <input
                    ref="photoFeild"
                    @change="savePhoto()"
                    id="photo_file"
                    style="visibility: hidden"
                    accept="image/*,application/*"
                    type="file"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="font-weight-bold pb-2 text-body-1">
                  PAN
                </div>
                <div class="upload-main" elevation="0">
                  <v-icon v-if="!pan_preview" class="upload-icon"
                    >mdi-cloud-upload-outline</v-icon
                  >
                  <label for="pan_file" class=" upload-box">
                    <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                    <div v-if="pan_preview">
                      <v-img
                        width="178px"
                        height="147px"
                        alt="pan"
                        class="rounded-lg"
                        v-if="checkForImage(pan_file, pan_preview)"
                        :src="pan_preview"
                      >
                      </v-img>
                      <v-img
                        width="178px"
                        height="147px"
                        alt="pan"
                        class="rounded-lg"
                        v-else
                        src="@/assets/images/file_logo.png"
                      ></v-img>
                    </div>
                  </label>
                  <input
                    ref="panFeild"
                    @change="savePan()"
                    id="pan_file"
                    style="visibility: hidden"
                    accept="image/*,application/*"
                    type="file"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="font-weight-bold pb-2 text-body-1">
                  Aadhaar Front Back
                </div>
                <div class="upload-main" elevation="0">
                  <v-icon v-if="!aadhaar_front_back_preview" class="upload-icon"
                    >mdi-cloud-upload-outline</v-icon
                  >
                  <label for="aadhaar_front_back_file" class=" upload-box">
                    <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                    <div v-if="aadhaar_front_back_preview">
                      <v-img
                        width="178px"
                        height="147px"
                        alt="aadhaar front back"
                        class="rounded-lg"
                        v-if="
                          checkForImage(
                            aadhaar_front_back_file,
                            aadhaar_front_back_preview
                          )
                        "
                        :src="aadhaar_front_back_preview"
                      >
                      </v-img>
                      <v-img
                        width="178px"
                        height="147px"
                        class="rounded-lg"
                        alt="aadhaar front back"
                        v-else
                        src="@/assets/images/file_logo.png"
                      ></v-img>
                    </div>
                  </label>
                  <input
                    ref="aadhaarFrontBackFeild"
                    @change="saveAadhaarFrontBack()"
                    id="aadhaar_front_back_file"
                    style="visibility: hidden"
                    accept="image/*,application/*"
                    type="file"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="font-weight-bold pb-2 text-body-1">
                  Aadhaar Front
                </div>
                <div class="upload-main" elevation="0">
                  <v-icon v-if="!aadhaar_front_preview" class="upload-icon"
                    >mdi-cloud-upload-outline</v-icon
                  >
                  <label for="aadhaar_front_file" class=" upload-box">
                    <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                    <div v-if="aadhaar_front_preview">
                      <v-img
                        width="178px"
                        height="147px"
                        alt="aadhaar front"
                        class="rounded-lg"
                        v-if="
                          checkForImage(
                            aadhaar_front_file,
                            aadhaar_front_preview
                          )
                        "
                        :src="aadhaar_front_preview"
                      >
                      </v-img>
                      <v-img
                        width="178px"
                        height="147px"
                        class="rounded-lg"
                        alt="aadhaar front"
                        v-else
                        src="@/assets/images/file_logo.png"
                      ></v-img>
                    </div>
                  </label>
                  <input
                    ref="aadhaarFrontFeild"
                    @change="saveAadhaarFront()"
                    id="aadhaar_front_file"
                    style="visibility: hidden"
                    accept="image/*,application/*"
                    type="file"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="font-weight-bold pb-2 text-body-1">
                  Aadhaar Back
                </div>
                <div class="upload-main" elevation="0">
                  <v-icon v-if="!aadhaar_back_preview" class="upload-icon"
                    >mdi-cloud-upload-outline</v-icon
                  >
                  <label for="aadhaar_back_file" class=" upload-box">
                    <!-- <div
                      style="margin-right: 37px; margin-top: 21%"
                      for="files"
                    >
                      Upload <br />Aadhaar Front
                    </div> -->
                    <div v-if="aadhaar_back_preview">
                      <v-img
                        width="178px"
                        height="147px"
                        class="rounded-lg"
                        alt="aadhaar back"
                        v-if="
                          checkForImage(aadhaar_back_file, aadhaar_back_preview)
                        "
                        :src="aadhaar_back_preview"
                      >
                      </v-img>
                      <v-img
                        width="178px"
                        alt="aadhaar back"
                        height="147px"
                        class="rounded-lg"
                        v-else
                        src="@/assets/images/file_logo.png"
                      ></v-img>
                    </div>
                  </label>
                  <input
                    ref="aadhaarBackFeild"
                    @change="saveAadhaarBack()"
                    id="aadhaar_back_file"
                    style="visibility: hidden"
                    accept="image/*,application/*"
                    type="file"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            elevation="0"
            rounded
            color="success"
            class="white--text px-4 mr-3 mb-3"
            :disabled="btn_loader"
            :loading="btn_loader"
            @click="closeDialog()"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import file_logo from "../../../assets/images/file_logo.png";
export default {
  data() {
    return {
      show_dialog: false,
      valid: true,
      btn_loader: false,
      document_type: "",
      documents_list: [],
      aadhaar_front_preview: null,
      aadhaar_front_file: null,
      aadhaar_back_preview: null,
      aadhaar_back_file: null,
      aadhaar_front_back_preview: null,
      aadhaar_front_back_file: null,
      pan_preview: null,
      pan_file: null,
      photo_preview: null,
      photo_file: null,
      applicants_kyc_data: {},
    };
  },
  methods: {
    async openKycDocument(value, applicants_kyc_data) {
      this.show_dialog = true;
      this.document_type = value.key;
      this.document_type = value.key;
      this.applicants_kyc_data = applicants_kyc_data;
      // console.log("this.applicants_kyc_data", this.applicants_kyc_data);
      this.assignUrl();
      // await this.getEnachDetails();
    },
    closeDialog() {
      this.show_dialog = false;
      this.$emit("reload");
    },
    savePhoto() {
      this.photo_file = this.$refs.photoFeild.files.item(0);
      // console.log("PHOTO", this.$refs.photoFeild.files);
      // this.photo_preview = URL.createObjectURL(this.photo_file);
      this.postKycDocuments("photo_file", this.photo_file);
    },
    savePan() {
      this.pan_file = this.$refs.panFeild.files.item(0);
      // console.log(this.$refs.panFeild.files);
      // this.pan_preview = URL.createObjectURL(this.pan_file);
      this.postKycDocuments("pan_card_file", this.pan_file);
    },
    saveAadhaarFront() {
      this.aadhaar_front_file = this.$refs.aadhaarFrontFeild.files.item(0);
      // console.log(this.$refs.panFeild.files);
      // this.aadhaar_front_preview = URL.createObjectURL(this.aadhaar_front_file);
      this.postKycDocuments("addhar_front_file", this.aadhaar_front_file);
    },
    saveAadhaarBack() {
      this.aadhaar_back_file = this.$refs.aadhaarBackFeild.files.item(0);
      // console.log(this.$refs.panFeild.files);
      // this.aadhaar_back_preview = URL.createObjectURL(this.aadhaar_back_file);
      this.postKycDocuments("addhar_back_file", this.aadhaar_back_file);
    },
    saveAadhaarFrontBack() {
      this.aadhaar_front_back_file = this.$refs.aadhaarFrontBackFeild.files.item(
        0
      );
      // console.log(this.$refs.panFeild.files);
      // this.aadhaar_front_back_preview = URL.createObjectURL(
      //   this.aadhaar_front_back_file
      // );
      this.postKycDocuments(
        "addhar_front_and_back_file",
        this.aadhaar_front_back_file
      );
    },
    checkForImage(file, url) {
      // console.log("file", file, url);
      // return true;
      if (file) {
        let type = file.type.split("/");
        let ext = type[1];
        if (ext == "png" || ext == "jpg" || ext == "jpeg") {
          return true;
        } else {
          return false;
        }
      } else {
        let text = url.split(".");
        let ext = text[text.length - 1];
        if (ext == "png" || ext == "jpg" || ext == "jpeg") {
          return true;
        } else {
          return false;
        }
      }
      // } else {
      //   this.photo_preview = '@/assets/images/file_logo.png';
      //   console.log("inside else", this.photo_preview);
      // }
    },
    assignUrl() {
      if (this.applicants_kyc_data) {
        this.aadhaar_front_preview = this.applicants_kyc_data.aadhar_card_front_file;
        this.aadhaar_back_preview = this.applicants_kyc_data.aadhar_card_back_file;
        this.aadhaar_front_back_preview = this.applicants_kyc_data.aadhar_card_front_and_back;
        this.pan_preview = this.applicants_kyc_data.pan_card_file;
        this.photo_preview = this.applicants_kyc_data.upload_photo_file;
      }
    },
    postKycDocuments(file_name, file) {
      const self = this;
      let key = `${self.decrypt(this.$route.params.id)}/`;
      // if (file_name == "photo_file") {
      //   this.photo_loader = true;
      // }
      // if (file_name == "pan_card_file") {
      //   this.pan_loader = true;
      // }
      // if (file_name == "addhar_front_file") {
      //   this.aadhaar_front_loader = true;
      // }
      // if (file_name == "addhar_back_file") {
      //   this.aadhaar_back_loader = true;
      // }
      // if (file_name == "addhar_front_and_back_file") {
      //   this.aadhaar_front_back_loader = true;
      // }
      const successHandler = () => {
        // let result = response.data.result;
        if (file_name == "addhar_front_file") {
          // this.aadhaar_front_loader = false;
          // this.adharCardFrontUrl = result.aadhar_card_front_file;
          this.aadhaar_front_preview = URL.createObjectURL(
            this.aadhaar_front_file
          );
        } else if (file_name == "addhar_back_file") {
          // this.aadhaar_back_loader = false;
          // this.adharCardBackUrl = result.aadhar_card_back_file;
          this.aadhaar_back_preview = URL.createObjectURL(
            this.aadhaar_back_file
          );
        } else if (file_name == "addhar_front_and_back_file") {
          // this.aadhaar_front_back_loader = false;
          // this.adharCardFrontBackUrl = result.aadhar_card_front_and_back;
          this.aadhaar_front_back_preview = URL.createObjectURL(
            this.aadhaar_front_back_file
          );
        } else if (file_name == "pan_card_file") {
          // this.pan_loader = false;
          // this.panCardUrl = result.pan_card_file;
          this.pan_preview = URL.createObjectURL(this.pan_file);
        } else if (file_name == "photo_file") {
          // this.photo_loader = false;
          // this.imageUrl = result.upload_photo_file;
          this.photo_preview = URL.createObjectURL(this.photo_file);
        }
      };
      const finallyHandler = () => {
        // console.log("FinallyHandler");
      };
      let form = new FormData();
      form.append(file_name, file);
      self.request_PUT(
        // self,
        // self.$urls.POST_KYC_DETAILS + `${key}/`,
        // form,
        // successHandler,
        // null,
        // finallyHandler

        self,
        self.$urls.POST_KYC_DETAILS,
        key,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
<style scoped>
.upload-box {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  gap: 10px;
  width: 180px;
  height: 150px;
  border: 1.8px dashed #989393;
  border-radius: 8px;
  line-height: 16px;
  color: #989393;
  /* background-color: #fff; */
  cursor: pointer;
}
.upload-main {
  position: relative;
  width: 180px;
  height: 150px;
}
.upload-icon {
  position: absolute;
  left: 50%;
  /* right: 50%; */
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 33px;
  color: #989393;
}
</style>
